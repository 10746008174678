<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <span class="font-weight-medium text-h6"
            >Formulario tarea {{ taskLabel }}</span
          >
        </div>
        <v-divider inset></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="9">
        <div>
          <v-data-table
            :headers="headers"
            :items="dataslots"
            class="elevation-1"
          >
            <template v-slot:[`item.dats_inc`]="{ item }">
              <v-simple-checkbox
                v-model="item.dats_inc"
                :ripple="false"
                @click="checkItem(item)"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="item['dats_edt'] == false && item.dats_inc"
                color="primary"
                @click="quitarCandado(item)"
                >mdi-lock</v-icon
              >
              <v-icon
                v-if="item['dats_edt'] == true && item.dats_inc"
                @click="quitarCandado(item)"
                >mdi-lock-open</v-icon
              >
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col sm="12" md="3">
        <v-row
          class="d-flex justify-space-between align-center pa-2 mr-2"
          style="background-color: #cf0021; color: white; height: 40px"
          ><span v-if="!preview">Inicio</span>
          <span v-if="preview">Previsualización</span>
          <v-icon
            v-if="taskName === 'Inicio'"
            color="white"
            class="mr-2"
            @click="preview = !preview"
          >
            mdi-dns </v-icon
          ><v-icon
            v-if="taskName !== 'Inicio'"
            color="white"
            @click="closeFormTask"
          >
            mdi-close
          </v-icon></v-row
        >
        <v-row v-if="!preview" class="pa-2 mr-2">
          <v-col
            ><v-select
              v-model="formIni.usr__cod"
              :items="grupos"
              :menu-props="{ maxHeight: '300' }"
              label="Actores"
              item-text="gpr_name"
              item-value="grp_id"
            >
              <template v-slot:item="data">
                <v-list-item v-bind="data.attrs" v-on="data.on">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.gpr_name
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-icon
                    v-if="data.item.numUsuarios > 0"
                    class="mx-2"
                    @click.stop="mostrarGrupo(data.item)"
                  >
                    mdi-eye
                  </v-icon>
                </v-list-item>
              </template>
            </v-select></v-col
          >
          <v-col cols="12">
            <v-textarea
              v-model="formIni.task_des"
              name="description"
              label="Descripción"
            ></v-textarea>
          </v-col>
          <v-btn
            block
            color="primary"
            elevation="2"
            x-large
            @click="guardarFormuInicio()"
            >Guardar</v-btn
          >
        </v-row>
        <v-row v-if="preview" class="pa-4">
          <draggable
            v-model="dataslotsForm"
            draggable=".item"
            style="width: 100%"
            @change="formOrder"
          >
            <div
              v-for="element in dataslotsForm"
              :key="element.dats_label"
              class="item divItemFormu my-2"
            >
              <v-text-field :label="element.dats_label"></v-text-field>
            </div>
          </draggable>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogUsers" max-width="400">
      <v-card>
        <v-card-actions
          style="background-color: #cf0021; color: white; height: 40px"
        >
          <v-spacer>{{ grupo.gpr_name }}</v-spacer>
          <v-icon color="white" @click="dialogUsers = false">
            mdi-close
          </v-icon>
        </v-card-actions>
        <v-card-text>
          <v-container>
            <ListaUsuarios :grupo="grupo"></ListaUsuarios>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.v-divider {
  margin-left: 0px !important;
  border-width: thin !important;
  border-color: #cf0021 !important;
  max-width: 100% !important;
  background-color: #cf0021 !important;
}

.divItemFormu {
  width: 90%;
  height: 80px;
  /* border-style: solid;
  border-color: #cf0021;*/
  cursor: move;
}
</style>

<script>
import draggable from 'vuedraggable';
import ListaUsuarios from '@/components/ListaUsuarios.vue';

export default {
  name: 'FormInicio',
  components: {
    draggable,
    ListaUsuarios
  },
  props: {
    urlServices: {
      type: String,
      default: '',
      required: true
    },

    process: {
      type: Object,
      default: () => ({}),
    },

    user: {
      type: String,
      default: '',
      required: true
    },
    fiid: {
      type: String,
      default: '',
      required: true
    },
    taskName:{
      type: String,
      default: '',
      required: true
    },
    taskLabel: {
      type: String,
      default: '',
      required: true
    },
    lego: {
      type: Object,
      default() {
        return { pos__lego: 1 };
      }
    }
  },
  data() {
    return {
      json: {},
      dataslots: [],
      headers: [
        { text: 'Label', align: 'start', sortable: false, value: 'dats_label' },
        {
          text: 'Tipo de dato',
          align: 'start',
          sortable: false,
          value: 'tdat_user'
        },
        { text: '', align: 'start', sortable: false, value: 'dats_inc' },
        { text: '', align: 'start', sortable: false, value: 'actions' }
      ],
      dataslotsForm: [],
      preview: '',
      grupos: [
        {
          gpr_des: '',
          gpr_name: '',
          grp_des: '',
          grp_id: '',
        }
      ],
      userGroup: false,
      formIni: {
        actors: '',
        description: ''
      },
      dialogUsers: false,
      grupo: {}
    };
  },
  watch: {
    taskName() {
      this.getDataslots();
      this.getFormTask();
    }
  },
  mounted: function() {
    this.getDataslots();
    this.getGroups();
    this.getFormTask();
    this.definePreview();
    // Listen to message from iframe element
  },
  methods: {
    getDataslots() {
      let jsonService = {
        dsSIRbpm_form_task: {
          eeSIRbpm_dats_task: [
            {
              picproc__name: this.process.proc__name,
              pictask__name: this.taskName,
              picproc__dats: '*',
              picusuario: '*'
            }
          ],
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'post',
        url: this.urlServices + 'web/Procesos/SIRbpm_form_task',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSIRbpm_form_task.eeEstados[0].Estado == 'OK') {
            currentObj.dataslots =
              response.data.dsSIRbpm_form_task.eebpm_form_task;
            currentObj.filtrarLista();
          } else {
            console.log(response.data.dsSIRbpm_form_task.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getGroups() {
      let jsonService = {
        dsSIRbpm_gruser: {
          SIRbpm_gruser: [
            {
              nombre: '*',
              nit: '*',
              grp_typ: 2,
              usr_cod: '*',
              grp_est: '0',
              incuser: true
            }
          ],
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'post',
        url: this.urlServices + 'web/Procesos/SIRbpm_gruser',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSIRbpm_gruser.eeEstados[0].Estado == 'OK') {
            response.data.dsSIRbpm_gruser.eebpm_grp_fe.forEach((element) => {
              currentObj.grupos.push(element);
            });
          } else {
            console.log(response.data.dsSIRbpm_gruser.eeEstados[0].Estado);
          }

          currentObj.grupos.forEach(function(element) {
            if (
              element.eebpm_userg != undefined &&
              element.eebpm_userg.length > 0
            ) {
              element.numUsuarios = element.eebpm_userg.length;
            } else {
              element.numUsuarios = 0;
            }
            element.listaVisible = false;
          }, this);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getFormTask() {
      let jsonService = {
        dsbpm_own_task: {
          eeSIRbpm_own_task: [
            {
              piccia__nit: '*',
              picusr__cod: '*',
              picproc__name: this.process.proc__name,
              pictask__name: this.taskName,
              picusuario: '*',
              piipos_lego: this.lego.pos__lego
            }
          ],
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'POST',
        url: this.urlServices + 'web/Procesos/SIRbpm_own_task',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsbpm_own_task.eeEstados[0].Estado == 'OK') {
            currentObj.formIni = response.data.dsbpm_own_task.eebpm_own_task[0];
            currentObj.formIni.usr__cod = parseInt(currentObj.formIni.usr__cod);
          } else {
            console.log(response.data.dsbpm_own_task.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    filtrarLista() {
      this.dataslotsForm = this.dataslots.filter(
        dataslot => dataslot.dats_inc == true
      );
    },
    quitarCandado(item) {
      let index = this.dataslotsForm.findIndex(
        dataslot => dataslot.proc_dats === item.proc_dats
      );
      this.dataslotsForm[index].dats_edt = !this.dataslotsForm[index].dats_edt;
      this.saveFormuTareas();
    },
    checkItem(item) {
      if (item.dats_inc === true) {
        let obj = Object.assign(item);
        this.dataslotsForm.push(obj);
      } else {
        if (this.dataslotsForm.length === 1) {
          this.deleteLastDataslot([...this.dataslotsForm]);
        }
        const index = this.dataslotsForm.findIndex(
          element => element.proc_dats === item.proc_dats
        );
        this.dataslotsForm.splice(index, 1);
      }
      this.formOrder();
      this.preview = true;
    },
    formOrder() {
      if (this.dataslotsForm.length >= 1) {
        for (let i = 0; i < this.dataslotsForm.length; i++) {
          this.dataslotsForm[i].dats_post = i + 1;
        }
        this.saveFormuTareas();
      }
    },
    deleteLastDataslot(dataslots) {
      dataslots[0].dats_inc = true;
      let jsonService = {
        dsSICUDbpm_form_task: {
          eebpm_form_task: dataslots,
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'DELETE',
        url: this.urlServices + 'web/Procesos/SICUDbpm_form_task',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSICUDbpm_form_task.eeEstados[0].Estado == 'OK') {
            console.log(response.data.dsSICUDbpm_form_task.eeEstados[0].Estado);
            currentObj.getDataslots();
          } else {
            currentObj.getDataslots();
          }
        })
        .catch(function(error) {
          currentObj.getDataslots();
          console.log(error);
        });
    },
    saveFormuTareas() {
      let jsonService = {
        dsSICUDbpm_form_task: {
          eebpm_form_task: this.dataslotsForm,
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: 'PUT',
        url: this.urlServices + 'web/Procesos/SICUDbpm_form_task',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSICUDbpm_form_task.eeEstados[0].Estado == 'OK') {
            console.log(response.data.dsSICUDbpm_form_task.eeEstados[0].Estado);
            currentObj.getDataslots();
          } else {
            currentObj.getDataslots();
          }
        })
        .catch(function(error) {
          currentObj.getDataslots();
          console.log(error);
        });
    },
    listaVisible(item) {
      item.listaVisible = !item.listaVisible;
    },
    guardarFormuInicio() {
      let method = 'PUT';

      let jsonService = {
        dsbpm_own_task: {
          eeDatos: [
            {
              picusrcod: this.user,
              picfiid: this.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eebpm_own_task: []
        }
      };

      jsonService.dsbpm_own_task.eebpm_own_task[0] = this.formIni;

      this.axios({
        method: method,
        url: this.urlServices + '/web/Procesos/SICUDbpm_own_task',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSICUDbpm_lego.eeEstados[0].Estado == 'OK') {
            console.log(response.data.dsSICUDbpm_lego.eeEstados[0].Estado);
          } else {
            console.log(response.data.dsSICUDbpm_lego.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // publicarMF() {
    //   let method = "PUT";

    //   let jsonService = {
    //     dsbpm_own_task: {
    //       eeDatos: [
    //         {
    //           picusrcod: this.user,
    //           picfiid: this.fiid,
    //           local_ip: "",
    //           remote_ip: "",
    //         },
    //       ],
    //       eebpm_own_task: [],
    //     },
    //   };

    //   jsonService.dsbpm_own_task.eebpm_own_task[0] = this.formIni;

    //   this.axios({
    //     method: method,
    //     url: this.urlServices + "/web/Procesos/SICUDbpm_own_task",
    //     data: jsonService,
    //   })
    //     .then(function (response) {
    //       if (response.data.dsSICUDbpm_lego.eeEstados[0].Estado == "OK") {
    //         console.log(response.data.dsSICUDbpm_lego.eeEstados[0].Estado);
    //       } else {
    //         console.log(response.data.dsSICUDbpm_lego.eeEstados[0].Estado);
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    mostrarGrupo(grupoSel) {
      this.dialogUsers = true;
      this.grupo = grupoSel;
    },
    closeFormTask() {
      this.$emit('updatingTask', false); // 1. Emitting
    },
    definePreview() {
      if (this.taskName === 'Inicio') {
        this.preview = false;
      } else {
        this.preview = true;
      }
    }
  }
};
</script>
