<template>
  <v-container fluid>
    <v-row align="center" class="divSuperior">
      <v-col xs="12" sm="2" class="d-flex align-center pl-8">
        <v-text-field
          v-model="process.proc__des"
          label="Nombre"
          placeholder="Microflujo"
          required
          @change="saveProcess(process)"
        ></v-text-field>
      </v-col>
      <v-col xs="12" sm="2" class="d-flex justify-start align-center">
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon
                v-show="process.new == false"
                @click="defineview('summary')"
                class="mx-2"
                >mdi-folder-outline</v-icon
              >
            </span>
          </template>
          <span>Sumario</span>
        </v-tooltip> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon
                v-show="process.new == false"
                class="mx-2"
                @click="
                  defineview('iframe');
                  changeUrl(
                    'HtaQuantum/procesos/bpm_dats_proc/html/bpm_dats_proc.html',
                    '2139'
                  );
                "
                >mdi-folder-outline</v-icon
              >
            </span>
          </template>
          <span>Librería de datos</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon
                v-show="process.new == false"
                class="mx-2"
                @click="
                  defineview('iframe');
                  changeUrl('manejoListas/html/manejoListas.html', '1940');
                "
                >mdi-format-list-bulleted</v-icon
              >
            </span>
          </template>
          <span>Manejo de listas</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon
                v-show="process.new == false"
                class="mx-2"
                @click="defineview('taskForm')"
                >mdi-dns</v-icon
              >
            </span>
          </template>
          <span>Formulario Inicio</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon
                v-show="process.new == false"
                class="mx-2"
                @click="defineview('graph')"
                >mdi-tournament</v-icon
              >
            </span>
          </template>
          <span>{{ diagram.title }}</span>
        </v-tooltip>
      </v-col>
      <v-col xs="12" sm="3" class="d-flex justify-start"
        ><v-dialog v-model="dialog2" persistent max-width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-select
              v-model="lego"
              :items="bloques"
              item-text="leg_nam"
              item-value="pos__lego"
              label="Bloque"
              :return-object="true"
            >
              <template v-slot:item="data">
                <v-list-item
                  v-bind="data.attrs"
                  v-on="data.on"
                  @click="clickBlock(data)"
                >
                  <v-icon
                    v-if="data.item.type__lego === 'Vertical'"
                    class="mr-2"
                  >
                    mdi-transit-connection
                  </v-icon>
                  <v-icon v-else class="mr-2">
                    mdi-transit-connection-horizontal
                  </v-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.leg_nam }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-icon
                    v-if="data.item.pos__lego !== 1"
                    class="mr-2"
                    @click="deleteBloque(data.item)"
                  >
                    mdi-trash-can
                  </v-icon>
                </v-list-item>
              </template>
            </v-select>
            <v-icon
              v-bind="attrs"
              class="mx-2"
              v-on="on"
              @click="dialog2 = true"
            >
              mdi-plus
            </v-icon>
          </template>
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-icon @click="dialog2 = false"> mdi-close </v-icon>
            </v-card-actions>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="bloqueNew.leg_nam"
                      label="Nombre"
                      placeholder="Bloque"
                      required
                      @change="saveBloque()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-radio-group
                      v-model="bloqueNew.type"
                      row
                      @change="saveBloque()"
                    >
                      <v-radio label="Vertical" value="Vertical"></v-radio>
                      <v-icon class="mr-4"> mdi-transit-connection </v-icon>
                      <v-radio label="Horizontal" value="Horizontal"></v-radio>
                      <v-icon class="mr-4">
                        mdi-transit-connection-horizontal
                      </v-icon>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col xs="12" sm="4" class="d-flex justify-start">
            <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon
                v-show="process.new == false"
                class="mx-2"
                @click="
                  defineview('iframe');
                  changeUrl('bitacirasPrievadas/html/bitacoraPrivada.html', '1940');
                "
                >mdi-message-reply-text-outline</v-icon
              >
            </span>
          </template>
          <span>Bitácora</span>
        </v-tooltip>
      </v-col>
      <v-col xs="12" sm="1" class="d-flex justify-end pr-8">
        <v-icon @click="openPublishDialog">mdi-upload</v-icon>
      </v-col>
    </v-row>
    <v-row v-if="showGraph && diagram.source != ''" class="pa-6">
      <bpmn-diagram :height="height" :width="width" :xml="diagram.source" />
    </v-row>
    <v-row v-else>
      <FormInicio
        v-if="showForm"
        :url-services="json.urlServices"
        :process="process"
        :user="json.user"
        :fiid="json.fiid"
        task-name="Inicio"
        task-label="Inicio"
      />
      <TareasDelBloque
        v-if="showLego"
        :lego="lego"
        :url-services="json.urlServices"
        :process="process"
        :user="json.user"
        :fiid="json.fiid"
      />
      <iframe
        v-if="showIframe"
        v-show="iframe.loaded"
        id="myIframe"
        :src="iframe.src"
        frameborder="0"
        height="880px"
        width="100%"
        style="overflow: none"
        @load="load()"
      />
    </v-row>
    <base-dialog
      :color="colors.primary"
      :dialog="this.$store.state.SICUDdeployMF.config.state"
      :loading="disabled"
      :text="$store.state.SICUDdeployMF.labels.text"
      :title="$store.state.SICUDdeployMF.labels.title"
    >
      <template #buttons>
        <v-row class="d-flex justify-space-around">
          <v-col class="px-5" cols="6">
            <v-btn
              block
              :color="colors.primary"
              :dark="!disabled"
              :disabled="disabled"
              elevation="1"
              @click="approvePublish"
            >
              <span class="text-capitalize">{{ labels.approve }}</span>
            </v-btn>
          </v-col>
          <v-col class="px-5" cols="6">
            <v-btn
              block
              :color="colors.primary"
              :dark="!disabled"
              :disabled="disabled"
              elevation="1"
              @click="closePublishDialog"
            >
              <span class="text-capitalize">{{ labels.cancel }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </base-dialog>
    <base-snackbar
      :color="colors.primary"
      :enabled="snackbar.enabled"
      :text="snackbar.text"
      @close="closeSnackbar"
    ></base-snackbar>
  </v-container>
</template>

<script>
import BaseDialog from '@/components/Base/BaseDialog.vue';
import BaseSnackbar from '@/components/Base/BaseSnackbar.vue';
import BpmnDiagram from '@/components/custom/BpmnDiagram.vue';
import FormInicio from '@/components/FormularioInicioTareas.vue';
import TareasDelBloque from '@/components/TareasDelBloque.vue';
import colors from '@/styles/colors.js';

export default {
  name: 'MicroflujosView',

  components: {
    BaseDialog,
    BaseSnackbar,
    BpmnDiagram,
    FormInicio,
    TareasDelBloque
  },

  data() {
    return {
      colors: undefined,
      diagram: {
        source: '',
        title: 'Diagrama'
      },
      disabled: false,
      labels: {
        approve: 'Si',
        cancel: 'No'
      },
      snackbar: {
        enabled: false,
        text: ''
      },
      json: '',
      process: {
        proc__name: '',
        proc__des: '',
        cia__nit: '',
        procname2: '',
        usr__cod: '',
        usr__cod__rule: '',
        screen__name: '',
        task__name: '',
        diagram__name: '',
        proc__est: '',
        proc_id: '',
        piidreg: '',
        new: true //cuando carge un procesos debe ser false
      },
      bloques: [],
      lego: {},
      bloqueNew: {
        type: '',
        leg_nam: ''
      },
      iframe: {
        src: '',
        loaded: false
      },
      dialog: false,
      dialog2: false,
      grupos: [],
      row: '',
      showForm: false,
      showGraph: false,
      showLego: false,
      showIframe: false,
      showSummary: false
    };
  },

  computed: {
    height() {
      return (this.$vuetify.breakpoint.height - 120).toString();
    },

    width() {
      this.getBpmnDiagram();
      return (this.$vuetify.breakpoint.width - 60).toString();
    }
  },

  watch: {
    json() {
      if (this.json.process_name !== '') {
        this.process.proc__name = this.json.process_name;
        this.process.proc__des = this.json.process_desc;
        this.process.proc_id = this.json.proc_id;
        this.process.new = false;
        this.getInfoProcess();
        this.getBloques();
      }
      this.iframe.src =
        this.json.url + 'ingresoExterno/html/ingresoExterno.html';
    },
    lego() {
      if (this.lego.pos__lego !== undefined) {
        this.defineview('lego');
      }
    }
  },

  mounted() {
    this.json = JSON.parse(
      this.$route.params.jsonparams.replaceAll('%2f', '/')
    );

    this.$store.dispatch('auth/setAuth', {
      picfiid: this.json.fiid,
      picusrcod: this.json.user
    });
  },

  created() {
    this.colors = colors;
  },

  methods: {
    approvePublish() {
      this.disabled = true;
      this.publishProcess();
    },

    cargarVista(url) {
      let paramText = JSON.stringify(this.json).replaceAll('/', '%2f');
      this.iframe.src = url + paramText;
      console.log(url + paramText);
    },

    changeUrl(url, func) {
      this.iframe.loaded = false;
      this.json.funcion = func;
      this.json.urlIframe = this.json.url + url;
      this.load();
    },

    cleanLego() {
      this.lego = {};
    },

    clickBlock(data) {
      this.$store.dispatch('SIRbpm_proc_grf/updateConfig', {
        state: false,
        icon: this.$store.state.icons.icons.find(
          icon => icon.key === 'monitor-eye'
        )
      });

      this.$store.dispatch('toggleGrid', true);

      this.$store.dispatch('toggleTitle', {
        currentBlockTitle: data.item.type__lego,
        type: 'block',
        text: data.item.type__lego
      });
    },

    closeSnackbar(event) {
      this.snackbar.enabled = event;
      this.$store.dispatch('messaging/setMessage', '');
    },

    closePublishDialog() {
      this.$store.commit('SICUDdeployMF/SET_STATE', false);
    },

    deleteBloque(lego) {
      let method = 'DELETE';

      let jsonService = {
        dsSICUDbpm_lego: {
          eeDatos: [
            {
              picusrcod: this.json.user,
              picfiid: this.json.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eebpm_lego: [
            {
              proc__name: lego.proc__name,
              type__lego: lego.type__lego,
              leg_nam: lego.leg_nam,
              pos__lego: lego.pos__lego
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: method,
        url: this.json.urlServices + '/web/Procesos/SICUDbpm_lego',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSICUDbpm_lego.eeEstados[0].Estado == 'OK') {
            currentObj.getBloques();
            currentObj.cleanLego();
            currentObj.defineview('inicio');
          } else {
            console.log(response.data.dsSICUDbpm_lego.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    defineview(opc) {
      if (opc === 'iframe') {
        this.showForm = false;
        this.showGraph = false;
        this.showLego = false;
        this.showIframe = true;
        this.showSummary = false;
        this.cleanLego();
      } else if (opc === 'lego') {
        this.showForm = false;
        this.showGraph = false;
        this.showLego = true;
        this.showIframe = false;
        this.showSummary = false;
      } else if (opc === 'taskForm') {
        this.showForm = true;
        this.showGraph = false;
        this.showLego = false;
        this.showIframe = false;
        this.showSummary = false;
        this.cleanLego();
      } else if (opc === 'summary') {
        this.showForm = false;
        this.showGraph = false;
        this.showLego = false;
        this.showIframe = false;
        this.showSummary = true;
        this.cleanLego();
      } else if (opc === 'graph') {
        this.getBpmnDiagram();
        this.showForm = false;
        this.showGraph = true;
        this.showLego = false;
        this.showIframe = false;
        this.showSummary = false;
        this.cleanLego();
      } else if (opc === 'inicio') {
        this.showForm = false;
        this.showGraph = false;
        this.showLego = false;
        this.showIframe = false;
        this.showSummary = false;
        this.cleanLego();
      }
    },

    getBloques() {
      let method = 'POST';

      let jsonService = {
        dsSIRbpm_lego: {
          eeDatos: [
            {
              picusrcod: this.json.user,
              picfiid: this.json.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eeSIRbpm_lego: [
            {
              picproc__name: this.process.proc__name,
              picpos__lego: '*',
              picusuario: '*'
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: method,
        url: this.json.urlServices + '/web/Procesos/SIRbpm_lego',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSIRbpm_lego.eeEstados[0].Estado == 'OK') {
            response.data.dsSIRbpm_lego.eebpm_lego.splice(0, 1);
            currentObj.bloques = response.data.dsSIRbpm_lego.eebpm_lego;
          } else if (response.data.dsSIRbpm_lego.eeEstados[0].Returnid == 98) {
            currentObj.bloques = [];
          } else {
            console.log(response.data.dsSIRbpm_lego.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    async getBpmnDiagram() {
      this.diagram.source = '';

      await this.$store.dispatch('SIRbpm_cmd/get', {
        picproc_name: this.json.process_name
      });

      this.diagram.source = this.$store.state.SIRbpm_cmd.diagramXML;
    },

    getInfoProcess() {
      let method = 'POST';

      let jsonService = {
        dsSIRbpm_proc: {
          eeDatos: [
            {
              picusrcod: this.json.user,
              picfiid: this.json.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eeSIRbpm_proc: [
            {
              picproc__name: this.process.proc__name,
              piiproc_id: this.process.proc_id,
              picusuario: '*',
              piccia__nit: '*'
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: method,
        url: this.json.urlServices + '/web/Procesos/SIRbpm_proc',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSIRbpm_proc.eeEstados[0].Estado == 'OK') {
            currentObj.process = {
              ...response.data.dsSIRbpm_proc.eebpm_proc[0]
            };
            currentObj.process.new = false;
          } else {
            console.log(response.data.dsSIRbpm_lego.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    load: function() {
      this.iframe.loaded = true;

      document
        .getElementById('myIframe')
        .contentWindow.postMessage(JSON.stringify(this.json), '*');
    },

    openPublishDialog() {
      this.disabled = false;
      this.$store.commit('SICUDdeployMF/SET_STATE', true);
    },

    saveBloque() {
      if (this.bloqueNew.type == '' || this.bloqueNew.leg_nam == '') {
        return;
      }

      let method = 'POST';

      let jsonService = {
        dsSICUDbpm_lego: {
          eeDatos: [
            {
              picusrcod: this.json.user,
              picfiid: this.json.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eebpm_lego: [
            {
              proc__name: this.process.proc__name,
              type__lego: this.bloqueNew.type,
              leg_nam: this.bloqueNew.leg_nam,
              pos__lego: ''
            }
          ]
        }
      };

      let currentObj = this;

      this.axios({
        method: method,
        url: this.json.urlServices + '/web/Procesos/SICUDbpm_lego',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSICUDbpm_lego.eeEstados[0].Estado == 'OK') {
            currentObj.getBloques();
            currentObj.bloqueNew.type = '';
            currentObj.bloqueNew.leg_nam = '';
            currentObj.dialog2 = false;
            currentObj.lego = {
              ...response.data.dsSICUDbpm_lego.eebpm_lego[0]
            };
          } else {
            console.log(response.data.dsSICUDbpm_lego.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    saveProcess(process) {
      let method = 'PUT';
      if (process.new == true) {
        method = 'POST';
      }

      let objSave = { ...process };
      // delete objSave.bloques;
      delete objSave.new;

      let jsonService = {
        dsSICUDbpm_proc: {
          eeDatos: [
            {
              picusrcod: this.json.user,
              picfiid: this.json.fiid,
              local_ip: '',
              remote_ip: ''
            }
          ],
          eebpm_proc: [objSave]
        }
      };

      let currentObj = this;

      this.axios({
        method: method,
        url: this.json.urlServices + '/web/Procesos/SICUDbpm_proc',
        data: jsonService
      })
        .then(function(response) {
          if (response.data.dsSICUDbpm_proc.eeEstados[0].Estado == 'OK') {
            currentObj.json.process_name =
              response.data.dsSICUDbpm_proc.eebpm_proc[0].proc__name;
            currentObj.json.proc_id =
              response.data.dsSICUDbpm_proc.eebpm_proc[0].proc_id;
            currentObj.json.process_desc =
              response.data.dsSICUDbpm_proc.eebpm_proc[0].proc__des;
            currentObj.process = {
              ...response.data.dsSICUDbpm_proc.eebpm_proc[0]
            };
            currentObj.process.new = false;
            console.log(currentObj.process);
            currentObj.getBloques();
          } else {
            console.log(response.data.dsSICUDbpm_proc.eeEstados[0].Estado);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    async publishProcess() {
      await this.$store.dispatch('SICUDdeployMF/get', {
        proc_name: this.process.proc__name
      });

      this.closePublishDialog();

      if (this.$store.state.SICUDdeployMF.config.response) {
        let currentObj = this;
        window.location.href =
          currentObj.json.url + 'procesos/html/procesos.html';
      } else {
        this.snackbar.text = this.$store.state.messaging.message;
        this.snackbar.enabled = true;
      }
    }
  }
};
</script>

<style>
* {
  padding: 0px;
  margin: 0px;
}

body {
  padding: 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
}

.divSuperior {
  background-color: #cccccc;
  height: 80px;
}

/*---------- scroll ----------*/
::-webkit-scrollbar {
  width: 7px;
  background-color: white;
}
::-webkit-scrollbar:horizontal {
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b5b4b4;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b5b4b4;
}
/*---------- end scroll ----------*/
</style>
